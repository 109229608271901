





















import {Component, Vue, Watch} from 'vue-property-decorator';
import {NavigationItemInterface} from '@/interfaces/NavigationItem.interface';
import {NavigationItemsStorage} from '@/misc/NavigationItemsStorage';
import Tenant from '@/models/Tenant';
import {namespace} from 'vuex-class';
import {tenantStoreActions, tenantStoreGetter, tenantStoreMutations} from '@/stores/tenant.store';

const TenantStore = namespace('tenant');

@Component({})
export default class NavigationBarComponent extends Vue {
  @TenantStore.Action(tenantStoreActions.LOAD_TENANT_ACTION)
  private loadActiveTenantAction!: (tenantId: string) => Promise<Tenant>;
  @TenantStore.Getter(tenantStoreGetter.ACTIVE_TENANT)
  private _tenant?: Tenant;
  @TenantStore.Mutation(tenantStoreMutations.ACTIVE_TENANT)
  private activeTenant!: (tenant: Tenant) => void;

  private selectedNavItem = 'tenantDashboard';
  private navigationItems: NavigationItemInterface[] = [];

  get tenant(): Tenant | undefined {
    return this._tenant ? this._tenant : undefined;
  }


  constructor() {
    super();
    this.setNavigationItems();
  }
  /**
   * Watch the route url for changing the navigation bar
   */
  @Watch('$route', {immediate: true, deep: true})
  private onUrlChange(routerValue: any) {
    // handle loading of activeTenant store value!

    this.handleActiveTenantLoading();

    if (routerValue.name) {
      this.selectedNavItem = routerValue.name;
    }
    this.setNavigationItems();
  }

  /**
   *  Loads the active tenant according to the given parameter
   */
  private handleActiveTenantLoading() {
    try {
      if (this.$route.params.tenantId) { // The tenantId in the url is prioritized
        this.loadActiveTenantAction(this.$route.params.tenantId);
      } else if (this.tenant && this.tenant.id) { // Else check the activeTenant in the vuex-store
        this.loadActiveTenantAction(this.tenant.id);
      }
    } catch (e) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.ACTIVE_TENANT_ERROR');
    }
  }

  public isCurrentRoute(item: NavigationItemInterface) {
    return (this.selectedNavItem === item.routerName || item.childs?.includes(this.selectedNavItem));
  }
  public getIconColor(item: any) {
    return this.isCurrentRoute(item) ? 'background' : 'navbar-icons';
  }

  /**
   * Determines the navigation items regarding the current user role
   */
  private setNavigationItems() {
    this.navigationItems = NavigationItemsStorage
        .filter((item) => {
          return this.$userRoleHandler.hasPermission(item.permission);
        });
    // check if tenant id is available in url
    if (!this.$route.params.tenantId) {
      this.navigationItems = this.navigationItems.filter((item) => !item.tenantRequired);
    }
  }

  /**
   * On click handler that activates a route
   * @param item
   */
  public onNavItemClick(item: any) {
    this.selectedNavItem = item.routerName;
    this.$router.push({name: item.routerName, params: {tenantId: this.$route.params.tenantId}});
  }

}
