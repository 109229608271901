import {NavigationItemInterface} from '@/interfaces/NavigationItem.interface';
import i18n from '@/i18n';
import {Permission} from '@/misc/Enums/permission.enum';

export const NavigationItemsStorage: NavigationItemInterface[] =
    [
        {
            icon: 'mdi-factory',
            title: i18n.t('NAVIGATION.TOOLTIPS.TENANTS').toString(),
            routerName: 'tenantsOverview',
            permission: Permission.TENANT_READ_ALL,
            dropdownOptions: [],
            childs: [],
            tenantRequired: false,
            tooltip: 'TENANTS',
        },
        {
            icon: 'mdi-calendar-month',
            title: i18n.t('GENERAL.JOBS').toString(),
            routerName: 'jobsOverview',
            permission: Permission.JOB_READ_OWN,
            dropdownOptions: [],
            childs: [],
            tenantRequired: true,
            tooltip: 'JOBS',
        },
        {
            icon: 'mdi-timer',
            title: i18n.t('GENERAL.TIME_TRACKING').toString(),
            routerName: 'timetrackingOverview',
            permission: Permission.WORKSESSION_READ_OWN,
            dropdownOptions: [],
            childs: [],
            tenantRequired: true,
            tooltip: 'TIME_TRACKING',
        },
        {
            icon: 'mdi-map-marker',
            title: i18n.t('GENERAL.LOCATIONS').toString(),
            routerName: 'locationsOverview',
            permission: Permission.LOCATION_READ_OWN,
            dropdownOptions: [],
            childs: ['locationDashboard'],
            tenantRequired: true,
            tooltip: 'LOCATIONS',
        },
        {
            icon: 'mdi-account',
            title: i18n.t('GENERAL.EMPLOYEES').toString(),
            routerName: 'usersOverview',
            permission: Permission.USER_READ_OWN,
            dropdownOptions: [],
            childs: ['userDetails'],
            tenantRequired: true,
            tooltip: 'EMPLOYEES',
        },

        /* TODO HOLGER reimplement maps when they are working
          {
          icon: 'mdi-map-marker',
          title: i18n.t('GENERAL.MAP').toString(),
          routerName: 'mapOverview',
          // TODO holger change permission
          permission: Permission.LOCATION_READ,
          dropdownOptions: [],
          childs: [],
          tenantRequired: true,
          tooltip: 'MAP',
      },
         */

        {
            icon: 'mdi-cog',
            title: i18n.t('NAVIGATION.TOOLTIPS.SETTINGS').toString(),
            routerName: 'settings',
            permission: Permission.USER_READ_OWN,
            dropdownOptions: [],
            childs: [],
            tenantRequired: false,
            tooltip: 'SETTINGS',
        },
    ];
